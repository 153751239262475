import {nextTick} from 'vue'

let ERROR_DIV_CLASS = 'error'
let ERRORCLASS = 'error'
let ERROR_ELEMENT = 'small'

export function changeDefaults(errorDivClass, errorClass, errorElement) {
  ERROR_DIV_CLASS = errorDivClass
  ERRORCLASS = errorClass
  ERROR_ELEMENT = errorElement
}

const errorClassHandler = function (elem, value) {
  for (elem; elem && elem !== document; elem = elem.parentNode) {
    if (elem.__tfx_vue_component && 'hasError' in elem.__tfx_vue_component) {
      elem.__tfx_vue_component.hasError = value
    }
  }
}

const clearErrors = (form) => {
  Array.from(form.querySelectorAll(`${ERROR_ELEMENT}.${ERRORCLASS}`)).map(el => el.parentElement.removeChild(el))
  Array.from(form.querySelectorAll(`.${ERROR_DIV_CLASS}`)).forEach(el => {
    el.classList.remove(ERROR_DIV_CLASS)
    errorClassHandler(el, false)
  })
}

const visible = function (elem) {
  return !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)
}

const checkable = function (element) {
  return (/radio|checkbox/i).test(element.type)
}

const last = function (els) {
  return els[els.length - 1]
}

const checkPhoneField = function (elem) {
  const elValue = elem.value
  const code = elem.getAttribute('data-country-code')
  const clearValue = elValue.replace('+', '')
  if (elem.getAttribute('required') && elValue.length <= 7) {
    elem.setCustomValidity('Minimum 7 digits')
    return false
  } else if (elValue.length > 0 && elValue.length <= 7 && clearValue !== code) {
    elem.setCustomValidity('Minimum 7 digits')
    return false
  } else {
    return true
  }
}

const checkConfirmationField = function (elem) {
  const fieldId = elem.getAttribute('data-custom-validation-value')
  const fieldValue = document.getElementById(fieldId).value
  if (elem.value === fieldValue) {
    return true
  } else {
    const fieldName = elem.getAttribute('data-custom-validation-name')
    elem.setCustomValidity(`${fieldName} do not match`)
    return false
  }
}

const checkEmailDomain = function (elem) {
  if (elem.value.match(/.+\.taxesforexpats\.com/)) {
    elem.setCustomValidity('Invalid email address')
    return false
  } else {
    return true
  }
}
const checkNameField = function (elem) {
  if (elem.value.match(/[0-9a-zA-Z\- ]/)) {
    return true
  } else {
    elem.setCustomValidity('Invalid characters')
    return false
  }
}

// TODO: Move to registration component
const checkPasswordField = function (elem) {
  const errorMessage = 'See requirements on the right'
  const showCustomValidationMessage = elem.getAttribute('data-will-validate-message')
  if (elem.value.length < 8) {
    elem.setCustomValidity(showCustomValidationMessage ? errorMessage : 'Password must have minimum 8 characters')
    return false
  } else if (!elem.value.match(/[A-Z]/)) {
    elem.setCustomValidity(showCustomValidationMessage ? errorMessage : 'Password must have 1 capital letter')
    return false
  } else if (!elem.value.match(/\d/)) {
    elem.setCustomValidity(showCustomValidationMessage ? errorMessage : 'Please must have 1 number')
    return false
  } else {
    return true
  }
}

const checkCustomValidation = function (elem) {
  const customValidation = elem.getAttribute('data-custom-validation')
  if (customValidation) {
    switch (customValidation) {
      case 'FieldConfirmation':
        return checkConfirmationField(elem)
      case 'Password':
        return checkPasswordField(elem)
      case 'FieldPhone':
        return checkPhoneField(elem)
      case 'FieldEmail':
        return checkEmailDomain(elem)
      case 'Name':
        return checkNameField(elem)
    }
  }
  return true
}

const REQUIRED_MESSAGE = 'required'

const errorMessage = function (field) {
  const validity = field.validity
  const result = []
  if (validity.valueMissing) {
    const message = field.getAttribute('data-will-validate-message')
    if (message) {
      result.push(message)
    } else {
      result.push(REQUIRED_MESSAGE)
    }
  }
  if (validity.typeMismatch) {
    if (field.getAttribute('type') === 'email') {
      result.push('Invalid Email')
    } else {
      result.push('Please enter valid value')
    }
  }
  if (validity.patternMismatch) {
    if (field.getAttribute('data-validation-pattern-mismatch')) {
      result.push(field.getAttribute('data-validation-pattern-mismatch'))
    } else {
      result.push('Please enter valid value')
    }
  }
  if (validity.tooLong) {
    result.push('the value is longer than the permitted')
  }
  if (validity.tooShort) {
    const minlength = field.getAttribute('minlength')
    const err = minlength ? `Enter minimum ${minlength} character` : 'the value is shorter than the permitted'
    result.push(err)
  }
  if (validity.rangeUnderflow) {
    result.push('the value is lower than min')
  }
  if (validity.rangeOverflow) {
    result.push('the value is higher than max')
  }
  if (validity.stepMismatch) {
    const msg = field.getAttribute('step') === '1' ? 'the value must be an integer' : 'the value does not match the step'
    result.push(msg)
  }
  if (validity.badInput) {
    result.push('the entry cannot be converted to a value')
  }
  if (validity.customError) {
    result.push(field.validationMessage)
  }
  // return field.getAttribute('errorMessage') ? field.getAttribute('errorMessage') : field.validationMessage
  return result.join('<br />')
}

export const clearError = function (field, checkValidity) {
  if (field.setCustomValidity) {
    field.setCustomValidity('')
  }

  field.parentElement.classList.remove(ERROR_DIV_CLASS)

  Array.from(field.parentElement.querySelectorAll(`${ERROR_ELEMENT}.${ERRORCLASS}`)).map(el => el.parentElement.removeChild(el))
  if ((checkValidity === undefined || checkValidity === true) &&
    field.willValidate && visible(field) && !(field.checkValidity() && checkCustomValidation(field))) {
    markInvalide(field, errorMessage(field))
  } else {
    errorClassHandler(field, false)
  }
}

function findAllCheckableElements(form, name) {
  const el = form.elements[name]
  if (el) {
    const result = Array.from(form.elements[name])
    return result.length > 0 ? result : [el]
  } else {
    return []
  }
}

const clearErrorHandler = function (event) {
  const field = event.target
  if (checkable(field)) {
    findAllCheckableElements(field.form, field.name).forEach((field) => {
      clearError(field)
    })
  } else if (field.classList.contains('multiselect__input')) {
    const selectInput = field.closest('div.multiselect').parentElement
      .querySelector('input.select-tfx-hidden-accessible')
    if (selectInput) {
      nextTick(() => {
        clearError(selectInput)
      })
    }
  } else {
    clearError(field)
  }
}

const isLastFiledInGroup = function (field) {
  const groupName = field.dataset.group
  const groupElements = document.querySelectorAll(`input[data-group="${groupName}"]:not(:checked)`)

  return field === groupElements[groupElements.length - 1]
}

export const markInvalide = function (field, errorMessage) {
  if (field.dataset.group && !isLastFiledInGroup(field)) {
    return false
  }
  errorClassHandler(field, true)

  const small = document.createElement(ERROR_ELEMENT)
  small.innerHTML = errorMessage
  small.className = `form-control-feedback ${ERRORCLASS}`

  field.parentElement.appendChild(small)
  field.parentElement.classList.add(ERROR_DIV_CLASS)
}

// This is really problem with readonly && required fields
// This is by design. According to the official HTML5 standard drafts,
// https://html.spec.whatwg.org/multipage/input.html#the-readonly-attribute
// "if the readonly attribute is specified on an input element, the
// element is barred from constraint validation." (E.g. its values won't be checked.)
// https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#barred-from-constraint-validation
// so we have to check it explicitly, but only date picker
function checkForReadOnlyRequiredField(form, field) {
  if (visible(field) && field.hasAttribute('readonly') && field.hasAttribute('required') &&
    field.classList.contains('flatpickr-input')) {
    if (checkable(field) && field !== last(findAllCheckableElements(form, field.name))) {
      return
    }

    if (!field.value) {
      markInvalide(field, REQUIRED_MESSAGE)
      return true
    }
  }
  return false
}

function scrollToError(element, form) {
  const deviseType = form.getAttribute('data-devise-type')
  let deviseHeaderHeight = ''
  if (deviseType === 'Mobile') {
    deviseHeaderHeight = 88
  } else {
    deviseHeaderHeight = 108
  }
  const bodyRect = document.body.getBoundingClientRect()
  const elemRect = element.getBoundingClientRect()
  const offset = elemRect.top - bodyRect.top
  window.scrollTo({
    top: offset - deviseHeaderHeight,
    behavior: 'smooth'
  })
}

export function willvalidate(form) {
  clearErrors(form)
  let firstError = null

  Array.from(form).forEach(field => {
    if (field.willValidate && visible(field) && !(field.checkValidity() && checkCustomValidation(field))) {
      if (checkable(field) && field !== last(findAllCheckableElements(form, field.name))) {
        return
      }
      if (!firstError) firstError = field

      markInvalide(field, errorMessage(field))
    }

    if (checkForReadOnlyRequiredField(form, field)) {
      if (!firstError) firstError = field
    }
  })

  if (firstError) {
    if (form.getAttribute('data-disabled-scrolling') === 'Enabled') {
      scrollToError(firstError, form)
    } else if (form.getAttribute('data-disabled-scrolling') === 'Disabled') {
      firstError.focus()
    } else {
      firstError.focus()
      firstError.scrollIntoView({behavior: 'smooth', block: 'center'})
    }
  }
  return firstError === null
}

const willvalidatePlugin = {
  install(app) {
    app.config.globalProperties.willvalidate = willvalidate

    const validateOnSubmit = function (e) {
      const form = e.originalTarget || e.target
      const isFormValid = willvalidate(form)

      if (!isFormValid || form.willvalidateCallbacks) {
        e.preventDefault() // stop it's effects here
        e.stopImmediatePropagation() // stop it from bubbling up

        if (isFormValid) {
          form.willvalidateCallbacks(e)
        }
      }
    }

    const directiveBeforeMount = function (el, binding) {
      if (el.getAttribute('novalidate')) {
        return
      }
      if (typeof binding.value === 'function') {
        el.willvalidateCallbacks = binding.value
      }

      el.addEventListener('submit', validateOnSubmit, true)
      if (binding.arg !== 'on-submit') {
        el.addEventListener('change', clearErrorHandler)
        el.addEventListener('blur', clearErrorHandler, true)
      }

      el.setAttribute('novalidate', true)
    }

    const directiveUnmounted = function (el, binding) {
      el.removeEventListener('submit', validateOnSubmit)
      if (binding.arg !== 'on-submit') {
        el.removeEventListener('change', clearErrorHandler)
        el.removeEventListener('blur', clearErrorHandler)
      }

      el.removeAttribute('novalidate')

      delete el.willvalidateCallbacks
    }

    app.directive('willvalidate', {
      beforeMount: function (...args) {
        directiveBeforeMount(...args)
      },
      unmounted: function (...args) {
        directiveUnmounted(...args)
      }
    })
  }
}
export default willvalidatePlugin
