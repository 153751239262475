import {Notifier} from '@tfx/common/components/notifier'

export const notifier = new Notifier

document.addEventListener('turbolinks:visit', () => notifier.removeAll())

export function notify(title, opts) {
  if (opts.removeAll) {
    delete opts.removeAll
    notifier.removeAll()
  }
  notifier.notify(title, opts)
}
