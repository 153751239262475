export default class HowWeWorkComponent {
  constructor(el) {
    this.root = el
    this.windowHeight = window.innerHeight
    this.howWeWorkItemFirst = this.root.querySelector('.how-we-work__item.active')
    this.bodyRect = document.body.getBoundingClientRect()
    this.currentPositionScrolling = -this.bodyRect.top
    this.countStartSlide = 0
    this.timeAnimationItemInformation = 500
    this._bindEvents()
  }

  _bindEvents() {
    window.addEventListener('scroll', (event) => this._scrolling(event))
  }

  _scrolling() {
    if (pageYOffset >= (this.currentPositionScrolling - this.windowHeight / 2) && this.countStartSlide === 0) {
      this.countStartSlide++
      this._slideItems(this.howWeWorkItemFirst)
    }
  }

  _slideItems(el) {
    el.classList.add('active')
    this._progressActiveSlide(el.querySelector('[role=progress-active-slide]'))
    setTimeout(() => {
      const line = el.querySelector('[role=how-we-work-item-line]')
      if (line) {
        this._moveLine(el, line)
      } else {
        setTimeout(() => {
          this._clearAllActiveSlides()
          this._slideItems(this.howWeWorkItemFirst)
        }, 200)
      }
    }, 2500)
  }

  _moveLine(el, line) {
    let counter = 0
    let width = 0
    const i = setInterval(function () {
      line.style.width = width + '%'
      counter++
      width++

      if (counter === 101) {
        clearInterval(i)
        el.classList.add('reverse-move')
        this._reverseMoveLine(el, line, counter, width)
      }
    }.bind(this), 5)
  }

  _reverseMoveLine(el, line, counter, width) {
    const i = setInterval(function () {
      line.style.width = width + '%'
      counter--
      width--

      if (counter === -1) {
        clearInterval(i)
        const nextElement = el.nextElementSibling
        this._hideItemSlide(el)
        if (nextElement) {
          this._slideItems(nextElement)
        }
      }
    }.bind(this), 3)
  }

  _progressActiveSlide(el) {
    let counter = 0
    let width = 0
    const i = setInterval(function () {
      el.style.width = width + '%'
      counter++
      width++

      if (counter === 101) {
        clearInterval(i)
      }
    }, 25)
  }

  _hideItemSlide(el) {
    el.classList.remove('reverse-move')
  }

  _clearAllActiveSlides() {
    Array.from(this.root.querySelectorAll('[role=progress-active-slide]')).forEach((el) => {
      el.style.width = 0
    })
    Array.from(this.root.querySelectorAll('.how-we-work__item')).forEach((el) => {
      el.classList.remove('active')
    })
  }
}
