import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['step', 'image']

  connect() {
    this.currentStep = 0
    this.isAutoPlaying = true
    this.autoPlayInterval = null
    this.setupIntersectionObserver()
  }

  setupIntersectionObserver() {
    this.observer = new IntersectionObserver(this.handleIntersection.bind(this), {
      root: null,
      threshold: 0.5
    })
    this.observer.observe(this.element)
  }

  handleIntersection(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.startAutoPlay()
        this.observer.disconnect()
      }
    })
  }

  startAutoPlay() {
    this.updateUIForCurrentStep()
    this.autoPlayInterval = setInterval(() => {
      if (this.isAutoPlaying) {
        this.moveToNextStep()
      }
    }, 5000)
  }

  stopAutoPlay() {
    this.isAutoPlaying = false
    clearInterval(this.autoPlayInterval)
  }

  moveToNextStep() {
    this.resetStep(this.currentStep)

    if (this.currentStep === this.stepTargets.length - 1) {
      this.resetAllSteps()
      this.removeLineClassFromAllSteps()
      setTimeout(() => {
        this.currentStep = 0
        this.updateUIForCurrentStep()
      }, 50)
    } else {
      this.currentStep++
      this.updateUIForCurrentStep()
    }
  }

  resetAllSteps() {
    this.stepTargets.forEach(step => {
      step.classList.remove('progress-bar__step--active', 'progress-bar__step-line')
    })
  }

  resetStep(index) {
    this.stepTargets[index].classList.remove('progress-bar__step--active')
    this.imageTargets[index].classList.remove('progress-bar__image-pic--active')
  }

  updateUIForCurrentStep() {
    // Добавляем активные классы для текущего шага
    this.stepTargets[this.currentStep].classList.add('progress-bar__step--active')
    this.imageTargets[this.currentStep].classList.add('progress-bar__image-pic--active')
    this.addLineClassToStepsUpTo(this.currentStep)
  }

  addLineClassToStepsUpTo(index) {
    for (let i = 0; i <= index; i++) {
      this.stepTargets[i].classList.add('progress-bar__step-line')
    }
  }

  removeLineClassFromAllSteps() {
    this.stepTargets.forEach(step => {
      step.classList.remove('progress-bar__step-line')
    })
  }

  stepClick(event) {
    const clickedIndex = this.stepTargets.indexOf(event.currentTarget)
    if (clickedIndex !== -1) {
      this.stopAutoPlay()
      this.resetStep(this.currentStep)
      this.currentStep = clickedIndex
      this.updateUIForCurrentStep()
    }
  }
}
