import {getGeoAndClientData, countryName, countryCode,
  countryImage, countryPhone, countryGuide} from 'tfx-common/store/geolocation-clientdata'

export default {
  namespaced: true,
  actions: {
    getGeoData({commit}) {
      getGeoAndClientData()
    }
  },
  mutations: {
    ADD_GEO_DATA(state, data) {
    }
  },
  getters: {
    countryName: state => countryName.value,
    countryCode: state => countryCode.value,
    countryImage: state => countryImage.value,
    countryPhone: state => countryPhone.value,
    countryGuide: state => countryGuide.value
  }
}
