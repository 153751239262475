import {showTimedTooltip} from '@/common/api/tooltips'
const regexOnlyNumbers = /[^0-9+]/

function directiveBeforeMount(el) {
  const handler = function (e) {
    if (e.target.value.match(regexOnlyNumbers)) {
      showTimedTooltip(el, 'Numbers only, please!', 'only-latin-char')
      e.target.value = e.target.value.replace(regexOnlyNumbers, '')
      el.dispatchEvent(new CustomEvent('input'))
    }
  }
  el.addEventListener('input', handler)
}

export default {
  beforeMount(...args) {
    directiveBeforeMount(...args)
  }
}
