export default class TabComponent {
  constructor(el) {
    this.root = el
    this.tabsNavigation = this.root.querySelector('[data-tabs-navigation]')
    this.tabsNavigationItem = this.root.querySelectorAll('[data-tabs-navigation-item]')
    this._bindEvents()
  }

  _bindEvents() {
    Array.from(this.tabsNavigationItem).forEach(function (el) {
      el.addEventListener('click', (e) => this._changeTabs(e))
    }.bind(this))
  }

  _changeTabs(e) {
    e.stopPropagation()
    const currentElement = e.currentTarget
    const id = currentElement.getAttribute('data-tabs-navigation-item')
    Array.from(currentElement.closest('[data-tabs]').querySelectorAll('[data-tabs-navigation-item]')).forEach(function (el) {
      el.classList.remove('active')
    })
    currentElement.classList.add('active')
    this._showTabContentByActiveTab(id, currentElement)
  }

  _showTabContentByActiveTab(id, element) {
    Array.from(element.closest('[data-tabs]').querySelectorAll('[data-tabs-content-item]')).forEach(function (el) {
      el.classList.add('hide')
    })
    Array.from(element.closest('[data-tabs]').querySelectorAll('[data-tabs-content-item="' + id + '"]')).forEach((el) => {
      el.classList.remove('hide')
    })
  }
}
