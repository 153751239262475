import Http from 'vue-resource/src/http'
import {notify} from 'static/modules/notifier'
import {createAppComponent} from 'static/initializers/vue-components'

const state = {
  showDialog: false,
  openedDialog: [],
  closeDialogCallback: null
}

export function loadModalDialog(url, {method = 'get', callback} = {}) {
  const params = {dialog: 1}
  const request = method === 'post'
    ? Http.post(url, params)
    : Http.get(url, {params})

  request.then(
    (response) => {
      if (response.headers.get('content-type').startsWith('application/javascript')) {
        // eslint-disable-next-line no-eval
        eval(response.body)
        if (callback) {
          callback(null)
        }
      } else {
        const elDialogRoot = document.createElement('div')

        const componentApp = createAppComponent({
          template: response.body
        })

        const component = componentApp.mount(elDialogRoot)
        const container = document.querySelector('.modal-container')

        const dialogId = component.$el.id
        if (dialogId) {
          const oldDialog = container.querySelector(`#${dialogId}`)
          if (oldDialog) container.removeChild(oldDialog)
        }

        registerOpenDialog(component.$el)

        container.appendChild(elDialogRoot)
        if (callback) {
          callback(component)
        }
      }
    },
    (response) => {
      if (response.body && response.body.errors) {
        notify(response.body.errors.join('. '), {type: 'error'})
      } else {
        notify('Error', {type: 'error'})
      }
    }
  )

  state.openedDialog.forEach(closeModalDialog)
}

function registerOpenDialog(el) {
  state.openedDialog.push(el)
  prepareModalDialog(el)

  if (!state.closeDialogCallback) {
    state.closeDialogCallback = function (event) {
      event.preventDefault()
      closeModalDialog()
    }
  }

  el.addEventListener('click', (e) => {
    if (e.target.closest('[data-modal-action=close]')) {
      state.closeDialogCallback(e)
    }
  })
}

function prepareModalDialog(el) {
  document.body.classList.add('overflow-hidden')
  document.querySelector('.modal-container').classList.remove('modal-container-hide')
  document.querySelector('.global-overlay').classList.add('global-overlay-show')
  el.classList.add('modal-displayed', 'modal-show-window')
}

export function closeModalDialog() {
  const dialog = state.openedDialog.pop()

  if (dialog) {
    dialog.classList.toggle('modal-displayed')
    const parent = dialog.parentNode
    dialog.__tfx_vue_component.$.appContext.app.unmount()
    parent.remove()
  }

  if (!state.openedDialog.length) {
    restoreStateAfterDialogClose()

    document.body.classList.remove('overflow-hidden')
    document.querySelector('.modal-container').classList.add('modal-container-hide')
    document.querySelector('.global-overlay').classList.remove('global-overlay-show')
  }
}

function restoreStateAfterDialogClose() {
  const returnUrlDiv = Array.from(document.querySelectorAll('#return-cpath-div'))
  if (returnUrlDiv.length) {
    const returnUrl = returnUrlDiv[returnUrlDiv.length - 0]
    history.replaceState(null, '', returnUrl.dataset.returnCpath)
    returnUrlDiv.forEach((el) => el.parentElement.removeChild(el))
  }
}
