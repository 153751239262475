import Http from 'vue-resource/src/http'

export class ArticlesCategoryPageComponent {
  constructor(el) {
    this.root = el.element
    this.wrapperArticleList = this.root.querySelector('[role=wrapper-article-list]')
    this.articleCategoriesSelect = document.querySelector('[role=article-categories-select]')

    this.heightToScroll = window.innerHeight * 2
    this.bodyHeight = document.body.scrollHeight
    this.canAddNewArticles = true
    this.lastPage = false

    this.categoryId =this.root.getAttribute('data-id-category-articles')
    this.countArticles = parseInt(this.root.getAttribute('data-count-articles'))
    this.pageArticle = 2

    this.deviswType = this.root.getAttribute('data-devise-type')
    this.countAllArticles = parseInt(this.root.getAttribute('data-count-all-articles'))
    this.addFunctionInfiniteScroll = this.countAllArticles > this.countArticles

    this.bindEvents()
  }

  bindEvents() {
    if (this.addFunctionInfiniteScroll) {
      window.addEventListener('scroll', (e) => this.infiniteScroll(e))
    }
    if (this.articleCategoriesSelect) {
      this.articleCategoriesSelect.addEventListener('change', (e) => this.redirectToSelectedCategory(e))
    }
  }

  infiniteScroll() {
    const positionToShowArticles = this.bodyHeight - this.heightToScroll
    if (positionToShowArticles < pageYOffset && this.canAddNewArticles) {
      this.canAddNewArticles = false
      if (!this.lastPage) {
        this.showMoreArticles()
      }
    }
  }

  showMoreArticles() {
    return Http.get(`/articles-show-more?category_id=${this.categoryId}&page=${this.pageArticle}&count_articles=${this.countArticles}`)
        .then(function (response) {
          return response.json()
        }).then(function (data) {
          let htmlArticles = ''
          data.articles.forEach((article) => {
            htmlArticles += this.templateArticle(article)
          })

          this.wrapperArticleList.insertAdjacentHTML('beforeEnd', htmlArticles)

          if (data.last_page) {
            this.lastPage = true
          } else {
            ++this.pageArticle
          }

          this.canAddNewArticles = true
          this.bodyHeight = document.body.scrollHeight
        }.bind(this))
  }

  templateArticle(data) {
    return `<div class="articles-list-news__item">
      <div class="articles-list-news__item-content">
        <div class="articles-list-news__item-date">
          ${data.date}
        </div>
        <div class="articles-list-news__item-wrapper-title">
          <a href="${data.url}">
            <h2 class="articles-list-news__item-title">
              ${data.title}
            </h2>
          </a>
          <p class="articles-list-news__item-category">
            ${data.category}
          </p>
        </div>
        <a class="articles-list-news__item-link" href="${data.url}">READ MORE</a>
      </div>
      <a href="${data.url}" class="articles-list-news__item-photo">
        <img class="articles-list-news__item-image" alt="${data.title}" src="${data.photo}" />
      </a>
    </div>`
  }

  redirectToSelectedCategory() {
    location.href = this.articleCategoriesSelect.options[this.articleCategoriesSelect.selectedIndex].value
  }
}
