import {setGaUserId} from 'tfx-common/modules/externals-scripts'
import {getGeoAndClientData, clientData} from 'tfx-common/store/geolocation-clientdata'

export default {
  namespaced: true,
  actions: {
    getClientData({commit}) {
      getGeoAndClientData(() => {
        commit('ADD_CLIENT_DATA')
      })
    }
  },
  mutations: {
    ADD_CLIENT_DATA(state) {
      if (clientData.value.user_id) {
        setTimeout(() => setGaUserId(clientData.value.user_id), 200)
      }
    }
  },
  getters: {
    clientData: state => clientData.value
  }
}
