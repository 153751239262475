export default class TextTestimonialsPageComponent {
  constructor(el) {
    this.root = el
    this.containerMasonryListContainer = this.root.querySelector('[role=testimonials]')
    this.containerMasonryItems = this.root.querySelectorAll('.testimonials-page-block__item')
    this.buildMasonryReviews()
  }

  checkHeightColumns() {
    Array.from(this.containerMasonryListContainer.querySelectorAll('.testimonials-page-block__list-column')).forEach((el) => {
      const height = el.clientHeight
      el.setAttribute('data-height', height)
    })
  }

  setDataToEachItem(itemReview, number) {
    const arr = []
    Array.from(this.containerMasonryListContainer.querySelectorAll('.testimonials-page-block__list-column')).forEach((el) => {
      const height = el.getAttribute('data-height')
      arr.push(height)
    })
    const min = Math.min.apply(null, arr)
    this.containerMasonryListContainer.querySelector(`[data-height="${min}"]`).appendChild(itemReview)
    if (number >= 2) {
      this.checkHeightColumns()
    } else if (number === 0) {
      this.containerMasonryListContainer.querySelector('.testimonials-page-block__list-column:nth-child(1)')
          .setAttribute('data-height', '10')
    } else if (number === 1) {
      this.containerMasonryListContainer.querySelector('.testimonials-page-block__list-column:nth-child(2)')
          .setAttribute('data-height', '20')
    }
  }

  buildMasonryReviews() {
    Array.from(this.containerMasonryItems).forEach((el, number) => {
      setTimeout(() => {
        this.setDataToEachItem(el, number)
      }, 500)
    })
    this.containerMasonryListContainer.classList.remove('loader')
  }
}
