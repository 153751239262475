export default class ImageSizeComponent {
  constructor(el) {
    this.root = el
    this.articlePhoto = this.root.querySelector('[role=article-photo]')
    this.images = this.root.querySelectorAll('.brand-book-article-image img')
    this._bindEvents()
  }

  _bindEvents() {
    this.articlePhoto.addEventListener('change', (e) => this._uploadArticlePhoto(e))
  }

  _uploadArticlePhoto(event) {
    const reader = new FileReader()
    reader.onload = function () {
      Array.from(this.images).forEach((el) => {
        const image = el
        image.parentNode.classList.add('brand-book-article-image__photo--uploaded')
        image.setAttribute('src', reader.result)
      })
    }.bind(this)
    reader.readAsDataURL(event.target.files[0])
  }
}
