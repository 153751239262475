import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['placeholder']

  static values = {
    titles: Array
  }

  connect() {
    this.period = 3200
    this.isDeleting = true
    this.loopNum = 0
    this.renderText = this.titlesValue[0]

    this.typewriteTimer = setTimeout(() => this.tick(), this.period)
  }

  disconnect() {
    clearTimeout(this.typewriteTimer)
  }

  tick() {
    const currentTitleIndex = this.loopNum % this.titlesValue.length
    const currentTitleText = this.titlesValue[currentTitleIndex]

    let delta = 80

    if (this.isDeleting) {
      delta = 30
    }

    if (this.isDeleting) {
      this.renderText = currentTitleText.substring(0, this.renderText.length - 1)
    } else {
      this.renderText = currentTitleText.substring(0, this.renderText.length + 1)
    }

    if (!this.isDeleting && this.renderText === currentTitleText) {
      delta = this.period
      this.isDeleting = true
    } else if (this.isDeleting && this.renderText === '') {
      this.isDeleting = false
      this.loopNum++
      delta = 500
    }

    this.placeholderTarget.textContent = this.renderText

    this.typewriteTimer = setTimeout(() => this.tick(), delta)
  }
}
