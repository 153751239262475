import {showTimedTooltip} from '@/common/api/tooltips'
const regexOnlyLatin = new RegExp(/[^a-zA-Z0-9_\s~`!@#$%^&*()-=+\\|'";:\/?.>,<;]/) // eslint-disable-line

function directiveBeforeMount(el, binding) {
  const handler = function (e) {
    if (binding.value && binding.value.active === false) return

    if (e.target.value.match(regexOnlyLatin)) {
      showTimedTooltip(el, 'Invalid character', 'only-latin-char')
      e.target.value = e.target.value.replace(regexOnlyLatin, '')
      el.dispatchEvent(new CustomEvent('input'))
    }
  }
  el.addEventListener('input', handler)
}

export default {
  beforeMount(...args) {
    directiveBeforeMount(...args)
  }
}
