import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      clientLoggedData: 'authorization/clientData'
    }),
    loggedData: {
      get() {
        return this.clientLoggedData
      }
    }
  }
}
