import {Controller} from 'stimulus'

export default class extends Controller {
  mouseMoveCallback

  connect() {
    this.mouseMoveCallback = (event) => this.parallax(event)
    window.addEventListener('mousemove', this.mouseMoveCallback)
  }

  disconnect() {
    window.removeEventListener('mousemove', this.mouseMoveCallback)
  }

  parallax(event) {
    const _w = window.innerWidth/2
    const _h = window.innerHeight/2
    const _mouseX = event.clientX
    const _mouseY = event.clientY
    const _depth1 = `${50 - (_mouseX - _w) * 0.01}% ${50 - (_mouseY - _h) * 0.01}%`
    const _depth2 = `${50 - (_mouseX - _w) * 0.02}% ${50 - (_mouseY - _h) * 0.02}%`
    const _depth3 = `${50 - (_mouseX - _w) * 0.06}% ${50 - (_mouseY - _h) * 0.06}%`
    const x = `${_depth3}, ${_depth2}, ${_depth1}`
    this.element.style.backgroundPosition = x
  }
}
