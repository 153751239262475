import '@splidejs/splide/css'
import Splide from '@splidejs/splide'
import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['slider']

  connect() {
    if (window.innerWidth < 768) {
      this.slider = new Splide(
        this.sliderTarget,
        {
          classes: {
            pagination: 'splide__pagination'
          },
          type: 'loop',
          focus: 0,
          perPage: 1,
          autoWidth: true,
          perMove: 1,
          omitEnd: true,
          mediaQuery: 'min',
          arrows: false,
          dragMinThreshold: 10
        }
      ).mount()
    }
  }

  disconnect() {
    if (this.slider) {
      this.slider.destroy(true)
    }
  }
}
