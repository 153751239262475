import {Controller} from 'stimulus'

export default class extends Controller {
  static values = {variant: String}

  connect() {
    const testName = 'ab_test_new_pricing'
    if (!localStorage.getItem(testName)) {
      window.tfxEvent('ab_test', {
        name: testName,
        option: this.variantValue,
        users: 'all'
      })
      window.tfxEvent('ab_test_start', {
        name: testName,
        option: this.variantValue,
        users: 'all'
      })
      localStorage.setItem(testName, true)
    }
  }
}
