import {Controller} from 'stimulus'
import {isMobileApp} from '@tfx/common/modules/mobile-app-functions'

export default class extends Controller {
  connect() {
    if (isMobileApp()) {
      this.element.classList.add('is-mobile-app')
    }
  }
}
