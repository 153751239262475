import {createStore} from 'vuex'
import authorization from 'store/modules/authorization/store'
import geoLocation from 'store/modules/geolocation/store'

export const store = createStore({
  modules: {
    authorization,
    geoLocation
  }
})
