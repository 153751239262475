import {Controller} from 'stimulus'
import {useClickOutside} from 'stimulus-use'
import {initializeSearchiq} from 'static/initializers/externals'
export default class extends Controller {
  static targets = ['searchForm', 'sectionsContainer', 'section', 'tab']

  connect() {
    if (this.hasSectionsContainerTarget) {
      useClickOutside(this, this.sectionsContainerTarget)
    }

    if (document.body.querySelector('#siq_search_results')) {
      initializeSearchiq()
    }
  }

  clickOutside() {
    this.hideAllSections()
  }

  openSearchForm() {
    this.searchFormTarget.classList.add('open-search')
    document.body.querySelector('#mobile-navigation').classList.add('hide')
    document.body.querySelector('#header-authorization-mobile').classList.add('hide')

    initializeSearchiq()
  }

  closeSearchForm() {
    this.searchFormTarget.classList.remove('open-search')
    document.body.querySelector('#mobile-navigation').classList.remove('hide')
    document.body.querySelector('#header-authorization-mobile').classList.remove('hide')
  }

  hideAllSections() {
    this.sectionTargets.forEach(el => el.classList.remove('active'))
  }

  showThisSection({target}) {
    const activeSection = target.parentElement

    if (activeSection.classList.contains('active')) {
      target.parentElement.classList.remove('active')
    } else {
      const dropDownEl = activeSection.querySelector('.static-header__nav-drop-down') ||
        activeSection.querySelector('.static-header__small-drop-down')
      dropDownEl.classList.remove('hide')
      setTimeout(() => {
        this.sectionTargets.forEach(el => el.classList.remove('active'))
        target.parentElement.classList.add('active')
      })
    }
  }

  showTabSection({target}) {
    this.tabTargets.forEach(el => el.classList.remove('active'))
    target.parentElement.classList.toggle('active')
  }
}
