let intersectionObserver

export default function() {
  document.addEventListener('turbolinks:load', () => {
    initializeIntersectionObserver()
  })

  document.addEventListener('turbolinks:visit', () => {
    if (intersectionObserver) {
      intersectionObserver.disconnect()
      intersectionObserver = undefined
    }
  })
}

function initializeIntersectionObserver() {
  const images = document.querySelectorAll('[data-src], [data-background-image]')
  if (!images.length) {
    return
  }

  if ('IntersectionObserver' in window) {
    intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          const element = entry.target
          loadImage(element)
          intersectionObserver.unobserve(element)
        }
      })
    }, {rootMargin: '0px -50px 300px -50px'})
  }

  Array.from(images).forEach(image => {
    if (intersectionObserver) {
      intersectionObserver.observe(image)
    } else {
      loadImage(image)
    }
  })
}

function loadImage(element) {
  if (element.getAttribute('data-src')) element.src = element.getAttribute('data-src')
  if (element.getAttribute('data-background-image')) element.setAttribute('style', element.getAttribute('data-background-image'))
  const parent = element.parentElement
  if (parent.classList.contains('image-preloader')) {
    parent.classList.remove('image-preloader')
  }
}
