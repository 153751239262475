export function isMobileApp() {
  return !!window.ReactNativeWebView
}

export function sendMessageToMobileApp(event) {
  if (isMobileApp()) {
    window.ReactNativeWebView.postMessage(JSON.stringify(event))
  }
}

export function sendLogoutEvent() {
  sendMessageToMobileApp({type: 'logout'})
}

export function sendDownloadEvent(url, name) {
  sendMessageToMobileApp({type: 'download', url, name})
}

export function sendAuthAgainEvent() {
  sendMessageToMobileApp({type: 'authAgain'})
}
