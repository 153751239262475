import {Controller} from 'stimulus'
import {createAppComponent} from 'static/initializers/vue-components'

export default class extends Controller {
  static values = {
    endpoint: String
  }

  timeZoneChanged(event) {
    // ignore change event from hidden input, handle form only
    if (event.target.elements) {
      this.timeZone = event.target.elements.timeZoneSelect.value
      this.loadCalendar()
    }
  }

  timeFormatChanged(event) {
    this.format = event.target.checked ? '24' : ''
    this.loadCalendar()
  }

  onCalendarClick(event) {
    event.preventDefault()
    this.date = event.params.date
    this.loadCalendar()
  }

  loadCalendar() {
    fetch(this.url(this.date, this.timeZone, this.format))
      .then(response => response.text())
      .then(html => this.html2vue(html))
  }

  connect() {
    this.timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone
    this.loadCalendar()
  }

  disconnect() {
    if (this.vue) {
      this.vue.unmount()
    }
  }

  url(date, timezone, format) {
    const params = `?date=${date || ''}&consultation_timezone=${encodeURIComponent(timezone || '')}&consultation_format=${format || ''}`
    return this.endpointValue + params
  }

  html2vue(html) {
    if (this.vue) {
      this.vue.unmount()
      this.element.innerHTML = ''
    }

    this.vue = createAppComponent({
      template: html
    })

    const container = document.createElement('div')
    this.vue.mount(container)

    this.element.appendChild(container)
  }
}
