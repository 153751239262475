import {loadModalDialog} from 'static/modules/dialogs'

export default {
  created(el, binding) {
    el.addEventListener('click', (event) => {
      event.preventDefault()
      loadModalDialog(binding.value, {method: binding.modifiers.post ? 'post' : 'get'})
    })
  }
}
