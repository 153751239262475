import {ref, computed, nextTick} from 'vue'
import Http from 'vue-resource/src/http'

let loading = false
let loaded = false

const state = ref({
  countryCode: null,
  countryName: null,
  countryImage: null,
  countryPhone: null,
  countryGuide: null,
  clientData: {
    logged_in: false
  },
  deadlineData: null
})

// please don't use callback, this is deprecated
// and in use only in the app/javascript/common/components/DeadlineCounter.vue
export function getGeoAndClientData(callback) {
  if (loaded) {
    if (callback) callback()
    return
  }

  if (loading) return

  loading = true
  Http.get('/geocitylocation_clientdata').then(
    (response) => {
      loaded = true
      loading = true
      setupData(response.data)
      if (callback) {
        nextTick(() => {
          callback()
        })
      }
    }, (error) => {
      loaded = false
      loading = false
      console.error(error)
    }
  )
}

function setupData(data) {
  state.value.countryCode = data.country_code
  state.value.countryName = data.country_name
  const cntFlagImage = require(`svg-country-flags/svg/${data.country_code.toLowerCase()}.svg`)
  state.value.countryImage = cntFlagImage
  state.value.countryGuide = data.country_guide
  state.value.countryPhone = data.phone
  state.value.clientData = data.clientData
  state.value.deadlineData = data.deadlineData
}

export const countryCode = computed(() => {
  return state.value.countryCode
})

export const countryName = computed(() => {
  return state.value.countryName
})

export const countryImage = computed(() => {
  return state.value.countryImage
})

export const countryPhone = computed(() => {
  return state.value.countryPhone
})

export const countryGuide = computed(() => {
  return state.value.countryGuide
})

export const clientData = computed(() => {
  return state.value.clientData
})

export const deadlineData = computed(() => {
  return state.value.deadlineData
})
