import TextTestimonialsPageComponent from 'static/partials/text-testimonials-page'
import HowWeWorkComponent from 'static/partials/how-we-work'

import ImageSizeComponent from 'static/pages/imagesize'
import {ArticlesCategoryPageComponent} from 'static/pages/articles-category-page'

import TabComponent from 'static/modules/tab'
import {TabsWithHashComponent} from 'static/modules/tabs-with-hash'

let legacyComponents = []

export default function () {
  document.addEventListener('turbolinks:load', createComponents)
  document.addEventListener('turbolinks:visit', () => { legacyComponents = [] })
}

function createComponents() {
  const hashTabs = document.querySelector('[data-hash-tabs]')
  if (hashTabs) legacyComponents.push(new TabsWithHashComponent({element: hashTabs}))

  const testimonialsPage = document.querySelector('[data-testimonials-page]')
  if (testimonialsPage) legacyComponents.push(new TextTestimonialsPageComponent(testimonialsPage))

  const imageSize = document.querySelector('[data-image-size]')
  if (imageSize) legacyComponents.push(new ImageSizeComponent(imageSize))

  const howWeWork = document.querySelector('[data-how-we-work]')
  if (howWeWork) legacyComponents.push(new HowWeWorkComponent(howWeWork))

  const tabs = document.querySelectorAll('[data-tabs]')
  if (tabs.length) {
    Array.from(tabs).forEach((el) => {
      legacyComponents.push(new TabComponent(el))
    })
  }

  const articleCategory = document.querySelector('[role=articles-category]')
  if (articleCategory) legacyComponents.push(new ArticlesCategoryPageComponent({element: articleCategory}))
}
