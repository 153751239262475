import PNotify from './pnotify/pnotify'
import './pnotify/pnotifyButtons'

export class Notifier {
  notify(title, opts = {}) {
    const options = {
      title: title || false,
      text: opts.text || false,
      type: opts.type || 'notice',
      styling: {},
      hide: true,
      delay: opts.delay || 8000,
      dynamic: opts.dynamic || false,
      modules: {
        Buttons: {
          closer: true,
          closerHover: false,
          sticker: false,
          classes: {
            closer: 'tfx-notify-closer'
          }
        }
      },
      stack: {
        dir1: 'down',
        dir2: 'left',
        firstpos1: 100,
        firstpos2: 30,
        context: document.querySelector('.tfx-admin')
      }
    }

    let method = opts.type.toLowerCase()

    const infoIconHtml = '<div class="icon-info"></div>'
    let dynamicAnimation = '<div class="tfx-loader"><span></span><span></span><span></span><span></span><span></span><span></span><span></span><span></span></div>' // eslint-disable-line
    let errIconHtml = '<svg class="icon-error" id="icon-error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17"><path class="icon-error__bg" d="M8.5,0A8.5,8.5,0,1,1,0,8.5,8.5,8.5,0,0,1,8.5,0Z"/><path class="icon-error__sign" d="M9.37,11.43H7.65a.41.41,0,0,0-.3.13.44.44,0,0,0-.13.32v1.57a.44.44,0,0,0,.13.32.41.41,0,0,0,.3.13H9.37a.41.41,0,0,0,.3-.13.44.44,0,0,0,.13-.32V11.87a.44.44,0,0,0-.13-.32A.41.41,0,0,0,9.37,11.43Zm.52-7.27A.4.4,0,0,0,9.59,4H7.43a.4.4,0,0,0-.3.13A.42.42,0,0,0,7,4.47L7.2,9.86a.46.46,0,0,0,.14.32.42.42,0,0,0,.31.13H9.37a.42.42,0,0,0,.31-.13.46.46,0,0,0,.14-.32L10,4.47a.42.42,0,0,0-.12-.32Z"/></svg>' // eslint-disable-line
    let checkMarkHtml = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path class="icon-check-mark-path" d="M15.801 7.242l-.965-1.03A.636.636 0 0 0 14.354 6c-.19 0-.35.07-.483.212L9.215 11.19 7.13 8.955a.637.637 0 0 0-.483-.213c-.189 0-.35.071-.482.213l-.965 1.03A.726.726 0 0 0 5 10.5c0 .202.066.374.199.515l2.569 2.743.965 1.03a.636.636 0 0 0 .482.212c.19 0 .35-.07.483-.212l.965-1.03 5.138-5.485A.727.727 0 0 0 16 7.758a.726.726 0 0 0-.199-.516z"/></svg>' // eslint-disable-line
    let warningIconHtml = '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g fill="none" fill-rule="evenodd"><path fill="#D8D8D8" fill-opacity="0" d="M0 0h18v18H0z"/><circle cx="8.5" cy="8.5" r="8.5" fill="#F9630E" transform="translate(.5 .5)"/><g fill="#FFF"><path d="M9.86 11.925H8.134a.408.408 0 0 0-.303.133.44.44 0 0 0-.129.316v1.57a.44.44 0 0 0 .129.315.408.408 0 0 0 .303.134H9.86a.408.408 0 0 0 .304-.134.44.44 0 0 0 .128-.315v-1.57a.44.44 0 0 0-.128-.316.408.408 0 0 0-.304-.133zM10.376 4.658a.397.397 0 0 0-.3-.134H7.918a.397.397 0 0 0-.3.134.422.422 0 0 0-.118.315l.19 5.383c.004.121.05.226.137.315.088.089.19.133.307.133H9.86c.117 0 .22-.044.307-.133a.458.458 0 0 0 .138-.315l.189-5.383a.42.42 0 0 0-.118-.315z"/></g></g></svg>' // eslint-disable-line

    switch (method) {
      case 'info':
        options.addClass = 'tfx-notify tfx-notify-info'
        options.icon = infoIconHtml
        break
      case 'notice':
        options.addClass = opts.dynamic ? 'tfx-notify tfx-notify-alert tfx-notify-alert-dynamic' : 'tfx-notify tfx-notify-alert' // eslint-disable-line
        options.icon = opts.dynamic ? dynamicAnimation : errIconHtml
        options.hide = false
        break
      case 'background':
        options.addClass = 'tfx-notify tfx-notify-alert tfx-notify-alert-dynamic'
        options.icon = dynamicAnimation
        options.hide = false
        options.sticker = false
        method = 'notice'
        opts.notHide = true
        break
      case 'error':
        options.addClass = 'tfx-notify tfx-notify-error'
        options.icon = errIconHtml
        break
      case 'success':
        options.addClass = 'tfx-notify tfx-notify-success'
        options.icon = checkMarkHtml
        break
      case 'warning':
        options.addClass = 'tfx-notify tfx-notify-warning'
        options.icon = warningIconHtml
        break
    }
    options.hide = !opts.notHide
    options.textTrusted = !!opts.html

    if (method === 'warning') {
      method = 'info'
    }
    return PNotify[method](options)
  }

  removeAll() {
    PNotify.removeAll()
  }
}
