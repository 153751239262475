import {Controller} from 'stimulus'

export default class extends Controller {
  static values = {variant: String}

  connect() {
    const testName = 'final-changes'
    const tag = `ab_test_new_home_start-${testName}`
    if (!localStorage.getItem(tag)) {
      window.tfxEvent('ab_test', {
        name: testName,
        option: this.variantValue,
        users: 'all'
      })
      window.tfxEvent('ab_test_start', {
        name: testName,
        option: this.variantValue,
        users: 'all'
      })
      localStorage.setItem(tag, true)
    }
  }
}
