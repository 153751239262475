import {Controller} from 'stimulus'

const TRANSITION_CLASSNAME = 'banner__slider--transition'
const AUTOSHIFT_DELAY_MS = 5000

export default class extends Controller {
  static targets = ['slider', 'item']

  connect() {
    this.totalItems = this.itemTargets.length
    this.animationEnabled = this.totalItems > 1

    if (!this.animationEnabled) {
      return
    }

    this.index = 0
    this.allowShift = true
    this.autoShiftEnabled = this.itemTargets.length > 1

    this.sliderTarget.appendChild(this.itemTargets[0].cloneNode(true))
    this.sliderTarget.insertBefore(this.itemTargets[this.totalItems - 1].cloneNode(true), this.itemTargets[0])

    this.sliderTarget.addEventListener('transitionend', () => this.checkIndex())
    this.resizeEvemtHandler = () => this.onResize()
    window.addEventListener('resize', this.resizeEvemtHandler)

    this.sliderTarget.classList.add(TRANSITION_CLASSNAME)
    this.sliderTarget.style.left = `-${this.getItemWidth()}px`

    this.autoShiftSlide()
  }

  disconnect() {
    if (!this.animationEnabled) {
      return
    }
    window.removeEventListener('resize', this.resizeEvemtHandler)
    clearTimeout(this.timerId)
  }

  getItemWidth() {
    return this.itemTargets[0].offsetWidth
  }

  nextSlide() {
    this.disableAutoShift()
    this.shiftSlide(+1)
  }

  prevSlide() {
    this.disableAutoShift()
    this.shiftSlide(-1)
  }

  disableAutoShift() {
    if (this.autoShiftEnabled) {
      clearTimeout(this.timerId)
    }
    this.autoShiftEnabled = false
  }

  autoShiftSlide() {
    if (!this.autoShiftEnabled) {
      return
    }

    clearTimeout(this.timerId)
    this.timerId = setTimeout(() => this.shiftSlide(+1), AUTOSHIFT_DELAY_MS)
  }

  shiftSlide(offset) {
    this.sliderTarget.classList.add(TRANSITION_CLASSNAME)

    if (!this.allowShift) {
      return
    }
    this.allowShift = false

    this.index += offset

    this.sliderTarget.style.left = `-${(this.index + 1) * this.getItemWidth()}px`
  }

  checkIndex() {
    this.sliderTarget.classList.remove(TRANSITION_CLASSNAME)

    if (this.index === -1) {
      this.sliderTarget.style.left = `-${this.totalItems * this.getItemWidth()}px`
      this.index = this.totalItems - 1
    } else if (this.index === this.totalItems) {
      this.sliderTarget.style.left = `-${this.getItemWidth()}px`
      this.index = 0
    }

    this.autoShiftSlide()
    this.allowShift = true
  }

  onResize() {
    this.sliderTarget.classList.remove(TRANSITION_CLASSNAME)
    this.sliderTarget.style.left = `-${(this.index + 1) * this.getItemWidth()}px`
    this.autoShiftSlide()
    this.allowShift = true
  }

  closeBanner(event) {
    this.element.remove()
    localStorage.setItem(`webinar-banner-hide-${event.params.id}`, true)
  }
}
