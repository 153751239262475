import Turbolinks from 'turbolinks'
import {notify} from 'static/modules/notifier'

export default function () {
  let notice

  document.addEventListener(
    'turbolinks:load',
    function () {
      if (notice) {
        notify(notice.title ?? notice, {
          text: notice.text,
          type: 'success',
          removeAll: true
        })
        notice = undefined
      }
    },
    false
  )

  Turbolinks.reloadWithNotice = function (text) {
    notice = text
    this.reload()
  }

  Turbolinks.visitWithNotice = function (url, text, options) {
    notice = text
    this.visit(url, options)
  }

  Turbolinks.reload = function () {
    Turbolinks.visit(window.location, {action: 'replace'})
  }

  document.addEventListener('turbolinks:click', (event) => {
    const href = event.target.getAttribute('href')

    if (href?.charAt(0) === '#') {
      event.preventDefault()
    }
  }, true)

  Turbolinks.start()
}
