import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      geoCountryImage: null,
      geoCountryPhone: null,
      geoCountryName: null,
      geoCountryGuide: null,
      geoCountryCode: null
    }
  },
  computed: {
    ...mapGetters({
      countryName: 'geoLocation/countryName',
      countryCode: 'geoLocation/countryCode',
      countryImage: 'geoLocation/countryImage',
      countryPhone: 'geoLocation/countryPhone',
      countryGuide: 'geoLocation/countryGuide'
    }),
    geoCountry: {
      get() {
        if (!this.geoCountryName) {
          this.geoCountryName = this.countryName
        }
        return this.geoCountryName
      },
      set(value) {
        this.geoCountryName = value
      }
    },
    geoCode: {
      get() {
        if (!this.geoCountryCode) {
          this.geoCountryCode = this.countryCode
        }
        return this.geoCountryCode
      },
      set(value) {
        this.geoCountryCode = value
      }
    },
    geoImage: {
      get() {
        if (!this.geoCountryImage) {
          this.geoCountryImage = this.countryImage
        }
        return this.geoCountryImage
      },
      set(value) {
        this.geoCountryImage = value
      }
    },
    geoPhone: {
      get() {
        if (!this.geoCountryPhone) {
          this.geoCountryPhone = this.countryPhone
        }
        return this.geoCountryPhone
      },
      set(value) {
        this.geoCountryPhone = value
      }
    },
    geoGuide: {
      get() {
        if (!this.geoCountryGuide) {
          this.geoCountryGuide = this.countryGuide
        }
        return this.geoCountryGuide
      },
      set(value) {
        this.geoCountryGuide = value
      }
    }
  }
}
