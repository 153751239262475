import Http from 'vue-resource/src/http'

export function initVueResource() {
  const tokenHolder = document.querySelector('[name="csrf-token"]')

  if (tokenHolder !== null) {
    Http.headers.common['X-CSRF-TOKEN'] = tokenHolder.getAttribute('content')
  } else {
    // CSRF is disabled in the test environment so document.querySelector('[name="csrf-token"]') == null
    // TODO: Enable allow_forgery_protection in config/environments/test.rb
    Http.headers.common['X-CSRF-TOKEN'] = 'XXX'
  }

  if (!Http.headers.common['X-JSON-CLIENT-REQUEST']) {
    Http.headers.common['X-JSON-CLIENT-REQUEST'] = '1'

    Http.interceptors.push(function (_request) {
      return response => {
        if (response.status === 0) { // Network is unreachable
          return new Promise(() => window.location.reload())
        } else if (response.status === 302) {
          const location = response.headers.get('X-JSON-CLIENT-REDIRECT')

          if (location) {
            return new Promise(() => window.location.assign(location))
          }
        }
      }
    })
  }
}
