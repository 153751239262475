export function showTimedTooltip(element, text, tooltipClass) {
  const el = element.parentElement
  if (el && el.className.indexOf(tooltipClass) < 0) {
    el.dataset.tooltip = text
    el.className = `${el.className} tfx-tooltip ${tooltipClass} show`
    setTimeout(() => {
      el.className = el.className.replace(` tfx-tooltip ${tooltipClass} show`, '')
      el.dataset.tooltip = ''
    }, 2000)
  }
}
