import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['navigation', 'section']

  toggleVisibility({target}) {
    [target, this.navigationTarget].forEach(el => el.classList.toggle('open-mobile-nav'))
    this.hideAllSections()
  }

  hideAllSections() {
    this.sectionTargets.forEach(el => el.classList.remove('active'))
  }

  showThisSection({target}) {
    target.parentElement.classList.toggle('active')
  }

  disconnect() {
    this.hideAllSections()
  }
}
