import {Controller} from 'stimulus'
import {notify} from 'static/modules/notifier'

export default class extends Controller {
  static values = {notice: String, alert: String}

  connect() {
    if (this.noticeValue) {
      notify(this.noticeValue, {type: 'success'})
    } else if (this.alertValue) {
      notify(this.alertValue, {type: 'error'})
    }
  }
}
