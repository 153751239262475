import {Controller} from 'stimulus'

export default class extends Controller {
  static targets = ['image']

  currentSlideIndex = 0
  currentImage
  timer

  connect() {
    if (this.imageTargets.length > 1) {
      this.slide(true)
    }
  }

  disconnect() {
    if (this.imageTargets.length > 1) {
      clearTimeout(this.timer)
      this.currentImage.onload = undefined
    }
  }

  slide(inital) {
    if (this.currentSlideIndex >= this.imageTargets.length) {
      this.currentSlideIndex = 0
    }

    this.currentImage = this.imageTargets[this.currentSlideIndex]

    const slideProc = () => this.timer = setTimeout(() => {
      this.setActiveImage(this.currentImage)
      ++this.currentSlideIndex
      this.slide()
    }, inital ? 0 : 7000)

    this.currentImage.parentNode.classList.remove('hide')

    if (this.currentImage.complete) {
      slideProc()
    } else {
      this.currentImage.onload = slideProc
    }
  }

  setActiveImage(image) {
    this.imageTargets.forEach(x => x.parentNode.classList.remove('active'))
    image.parentNode.classList.add('active')
  }
}
