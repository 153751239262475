import {googleTagManager, intercomWidget, fbPixel} from '@tfx/common/modules/externals-scripts'
import {RAILS_ENV, SEARCH_IQ_KEY_TAXESFOREXPATS, SEARCH_IQ_KEY_TFXTAX} from '@tfx/common/constants.js'
import {clientData} from '@tfx/common/store/geolocation-clientdata'

export default function () {
  googleTagManager()
  fbPixel()

  if (RAILS_ENV === 'production') {
    setTimeout(initializeIntercom, 2000)
  }
}

export function initializeSearchiq() {
  if (RAILS_ENV !== 'production' && RAILS_ENV !== 'staging') return
  if (document.getElementById('siq-container')) return

  window.siqConfig = {
    engineKey: window.location.host === 'tfx.tax' ? SEARCH_IQ_KEY_TFXTAX : SEARCH_IQ_KEY_TAXESFOREXPATS,
    baseUrl: '//pub.searchiq.co/'
  }

  const script = document.createElement('script')
  script.src = `${window.siqConfig.baseUrl}js/container/siq-container-2.js?engineKey=${window.siqConfig.engineKey}`
  script.async = true
  script.id = 'siq-container'
  document.getElementsByTagName('head')[0].appendChild(script)
}

function initializeIntercom() {
  if (!clientData.value) {
    setTimeout(initializeIntercom, 200)
  } else {
    if (!clientData.value.intercom) {
      return
    }

    intercomWidget(clientData.value.intercom)
  }
}
